<template>
  <div class="flex flex-col">
    <div class="flex flex-row items-center">
      <div
        v-for="(lang, langIndex) in languages"
        :key="lang + langIndex"
        class="language-card"
        :class="{ 'ml-4': langIndex, 'language-card_active': lang === selectedLanguage  }"
        :title="languagesMap[lang].name"
        @click="selectedLanguage = lang"
      >
        <selected-icon
          class="selected-icon"
          :active="lang === selectedLanguage"
        />
        <div
          class="img"
          :style="{ backgroundImage: `url('https://cdn.ip2location.com/assets/img/flags/${languagesMap[lang].code}.png')` }"
        />
      </div>
    </div>
  </div>

</template>

<script>
import SelectedIcon from '@/components/chat-adding/SelectedIcon'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

export default {
  name: "SelectLanguage",

  props: [ 'value', 'languages' ],

  components: {
    SelectedIcon,
    ConfigField
  },

  data() {
    return {
      languagesMap: {
        'ru': {
          code: 'ru',
          name: 'Русский'
        },
        'en': {
          code: 'us',
          name: 'English'
        }
      }
    }
  },

  computed: {
    selectedLanguage: {
      get() {
        return this.value
      },
      set(lang) {
        this.$emit('input', lang)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin language-card-hover-state {
  transform: translateY(-5px);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
}

@mixin language-card-active-state {
  transform: translateY(-5px) scale(.8);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
}

@mixin language-card-disable-translate {
  transform: translateY(-5px) !important;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3) !important;
  cursor: default !important;
}

.language-card {
  position: relative;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
  transition: all .2s cubic-bezier(0.4, 0.0, 0.2, 1);
  cursor: pointer;
  user-select: none;

  * {
    user-select: none;
  }

  &:hover {
    @include language-card-hover-state
  }

  &:active {
    @include language-card-active-state
  }

  &_active {
    &:hover {
      @include language-card-disable-translate
    }

    &:active {
      @include language-card-disable-translate
    }
  }

  .selected-icon {
    position: absolute;
    top: -5px;
    right: -5px;
  }

  .img {
    border-radius: 4px;
    width: 50px;
    max-width: 100%;
    height: 30px;
    max-height: 100%;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}
</style>
